import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'gatsby';
import { HeaderAndFooterQuery } from '../../query';

export const ContactSection = ({ hideContact = false }: any) => {
  const contactData = HeaderAndFooterQuery();
  const {
    phoneNumber,
    telephoneNumber,
    faxNumber,
    address,
    email,
    whatsUpNumber,
    location,
  } = contactData.strapiGlobal;
  return (
    <section className="py-[40px] sm:py-[30px] bg-sky-100">
      <Container>
        <Row
          className={`row-cols-1 ${
            !hideContact ? 'row-cols-lg-4' : 'row-cols-lg-3'
          } row-cols-sm-2 md:gap-y-[50px] sm:gap-y-[30px] text-center border-solid border-[1px] py-4 border-sky-500 xs:border-none`}
        >
          <Col>
            <i className="line-icon-Geo2-Love text-gradient bg-sky-500 text-[32px] mb-[30px] sm:mb-[10px] inline-block md:mb-[15px]" />
            <div className="text-darkgray uppercase text-sm font-medium font-serif mb-[10px]">
              Address
            </div>
            <a
              aria-label="Company Location"
              className="w-[95%] lg:w-[90%] md:w-[60%] sm:w-[75%] xs:w-full mx-auto"
              href={location?.data?.location}
            >
              {address.data.address}
            </a>
          </Col>
          <Col>
            <i className="line-icon-Headset text-gradient bg-sky-500 text-[32px] mb-[30px] sm:mb-[10px] inline-block md:mb-[15px]" />
            <div className="text-darkgray uppercase text-sm font-medium font-serif mb-[10px]">
              Let's Talk
            </div>
            <p className="w-[90%] lg:w-full mx-auto">
              <a
                aria-label="phone num"
                href={`tel:${phoneNumber}`}
                className="hover:text-sky-700"
              >
                Postcode: {phoneNumber}
              </a>
              <br />
              <a
                aria-label="phone num"
                href={`tel:${telephoneNumber}`}
                className="hover:text-sky-700"
              >
                Tel: {telephoneNumber}
              </a>
              <br />
              Fax: {faxNumber}
              <br />
              <a
                aria-label="Chat on WhatsApp"
                href={`https://wa.me/${whatsUpNumber}`}
                className="hover:text-sky-700"
              >
                WhatsApp Id: +86 135 8447 6806
              </a>
            </p>
          </Col>
          <Col>
            <i className="line-icon-Mail-Read text-gradient bg-sky-500 text-[32px] mb-[30px] sm:mb-[10px] inline-block md:mb-[15px]" />
            <div className="text-darkgray uppercase text-sm font-medium font-serif mb-[10px]">
              E-MAIL US
            </div>
            <p className="w-[90%] lg:w-full mx-auto">
              <a
                aria-label="mail"
                href={`mailto:${email}`}
                className="hover:text-sky-700"
              >
                {email}
              </a>
              <br />
            </p>
          </Col>
          {!hideContact && (
            <Col>
              <i className="icon-chat text-gradient bg-sky-500 text-[40px] mb-[30px] sm:mb-[10px] inline-block md:mb-[15px]" />
              <div className="text-darkgray uppercase text-sm font-medium font-serif mb-[10px]">
                Contact Us
              </div>
              <Link
                className="w-[75%] lg:w-full md:w-[60%] sm:w-[75%] mx-auto hover:text-sky-700"
                to={`/${contactData.strapiContact.slug}`}
              >
                Send Enquiry
              </Link>
            </Col>
          )}
        </Row>
      </Container>
    </section>
  );
};
